.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    height: 50px;
    margin-top: auto;
    box-shadow: var(--medium-shadow);
}

.bigTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}