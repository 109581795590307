.wrapper {
    
}

.graphContainer {
    position: relative;
    margin: auto;
    padding-top: 130px;
    margin-bottom: 50px;
    max-width: 960px;
    height: 800px;
}
