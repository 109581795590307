.body {
  background-image: url('./../../assets/images/background.jpg');
  background-size: cover;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.usersContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 960px;
  height: 100%;
  margin: auto;
  padding-top: 100px;

}
.cardContainer {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  
  width: 100%;
}

.logo{
position: absolute;
margin-top: -11.5px;
margin-left: -3px;
}