body {

  /* typography */
  --text-color: rgba(6, 6, 6, 1);
  --contrast-text-color: rgba(255, 255, 255, 1);

  /* backgrounds */
  --body-background: rgba(255, 255, 255, 1);

  /* colors */
	--primary-color: rgb(234, 205, 17);
  --accent-color: rgb(61, 64, 237);

  /* shadows */
  --small-shadow: 0 0 4px rgba(39, 39, 39, .125);
  --small-strong-shadow: 0 4px 4px rgba(39, 39, 39, .25);
  --medium-shadow: 0 0 8px rgba(39, 39, 39, 0.25);
  --large-shadow: 0 0 10px rgba(39, 39, 39, 0.25);
  --inner-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);


  /* spacing */
  --space: 8px;
  --medium-space: calc(var(--space) * 2);
  --large-space: calc(var(--space) * 4);

  /* borders */
  --border-width: 1px;
	--small-border-radius: 4px;
  --medium-border-radius: 8px;
  --large-border-radius: 16px;

  /* animations */
  --animation-duration: 0.3s;

  /* z-index */
  --entourage-depth: -1;
  --body-depth: 0;
  --userjourneypath-depth: 1;
  --userjourney-depth: 2;
  --actiontray-depth: 3;
  --modal-depth: 4;

  /* scroll bars */
  --scrollbar-width: 8px;
  --scroll-track-color: rgb(220, 220, 220);
  --scroll-thumb-color: rgb(190, 190, 190);

  /* Gradients */
  --button-gradient: linear-gradient(90.46deg, #f15a24 0%, #EB2931 50%, #C0118A 100%);
  
  --card-size: 30vw;
  --card-horizontal-offset: 5%;

}
