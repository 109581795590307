@import 'reset';
@import 'themes';
@import 'typography';

/* top-level containers */
html {
  width: 100%;
  height: 100%;
	font-size: 62.5%; /* 10px */
}

body {
  height: 100%;
  overflow-y: auto !important;
  overflow-x: hidden;
  font-family: 'work-sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1rem;
  background: var(--body-background);
  color: var(--text-color);
  z-index: var(--body-depth);
}

* {
  outline-color: var(--highlight-color);
}

/* anchors */
a, a:visited, a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

// /* scrollbars */
// ::-webkit-scrollbar {
//   width: var(--scrollbar-width);
//   height: var(--scrollbar-width);
// }

// ::-webkit-scrollbar-track {
//   background-color: var(--scroll-track-color);
// }

// ::-webkit-scrollbar-thumb {
//   background-color: var(--scroll-thumb-color);
//   border-radius: var(--border-radius);
// }

/* modals */
.react-responsive-modal-modal {
  overflow-y: visible;
  border-radius: var(--medium-border-radius);
}

.react-responsive-modal-closeButton {
  top: -30px;
  right: -36px;
  color: #fff;
  fill: #fff;
}
