.userCard {
    display:flex;
    background-color: white;
    margin: 8px;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    border-radius: var(--medium-border-radius);
    box-shadow: var(--large-shadow);
    text-align:center;
  }
  .userCardMobile {
    display:flex;
    background-color: white;
    margin: 8px;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 6px;
    border-radius: var(--medium-border-radius);
    box-shadow: var(--medium-shadow);
  }
  .userStats {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .userStatsMobile {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .userImage {
    border-radius: 50%;
    width: 200px;
    padding: 10px;
    margin: 10px 0px 10px 0px;
  
  
  }
  
  @media only screen and (max-width: 600px) {
    .userImage {
      margin: 0px 0px 0px 0px;
      width: 120px;
    }
  }
  
  .gradeLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgb(61, 64, 237);
    color: #FFFF;
    margin: 2px;
    height: 40px;
    padding: 10px;
    width: 60px; 
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
  }
  
  .counterLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgb(234, 205, 17);
    color: #FFFF;
    height: 40px;
    margin: 2px;
    width: 60px;
    border-radius: 10px;
    font-weight: bold;
    
  }