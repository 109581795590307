.wrapper {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    height: 100px;
    box-shadow: var(--medium-shadow);
    margin-top: -12px;
}

.bigTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 12px;
    
}