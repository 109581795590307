@font-face {
  font-family: 'space-grotesk';
  src: url('../fonts/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-neue';
  src: url('../fonts/HelveticaNeue.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-neue-bold';
  src: url('../fonts/HelveticaNeue Bold.ttf') format('truetype');
  font-style: normal;
}


h1 {
  font-family: 'helvetica-neue';
  font-size: 4.5rem;
  font-variation-settings: 'wght' 600;
  line-height: 40px;
}

h2 {
  font-family: 'helvetica-neue';
  font-size: 2.6rem;
  font-variation-settings: 'wght' 600;
  line-height: 32px;
}

h3 {
  font-family: 'helvetica-neue-bold';
  font-size: 1.4rem;
  font-variation-settings: 'wght' 600;
  line-height: 24px;
}

h4 {
  font-family: 'space-grotesk';
  font-size: 1.0rem;
  font-variation-settings: 'wght' 600;
  line-height: 12px;
}

p, input, label {
  display: inline-block;
  font-family: 'helvetica-neue';
  font-size: 1.5rem;
  font-variation-settings: 'wght' 400;
  // line-height: 24px;
}

big {
  font-family: 'helvetica-neue';
  font-size: 1.2em;
  font-variation-settings: 'wght' 400;
}

small {
  font-family: 'helvetica-neue';
  font-size: 0.8em;
  font-variation-settings: 'wght' 400;
}
