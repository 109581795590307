.wrapper {
    
}

.basicContainer {
    position: relative;
    margin: auto;
    text-align: center;
    text-justify: inter-cluster;
    padding-top: 130px;
    margin-bottom: 50px;
    max-width: 960px;
    height: 150px;
}
